import React, { useState } from 'react'
import { Container, Row, Col, Alert, Button, Modal, ModalBody, Spinner } from 'reactstrap'
import TopNavigation from '../../TopNavigation'
import withAppconfig from '../../AppContext'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import MatchCard from '../../components/Match/MatchCard'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const mapStateToProps = (state, props) => {
  const { firebase } = state
  if (!firebase.ordered.matches) return { matches: null }
  const matches = firebase.ordered.matches.map((m) => {
    return { id: m.key, ...m.value }
  })
  return {
    matches,
  }
}

const withMatches = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firebaseConnect((props) => [
    {
      path: 'matches/' + props.appconfig.accountkey + '',
      // queryParams: ['orderByChild=displayName'],
      storeAs: 'matches',
    },
  ]),
  connect(mapStateToProps)
)

function MatchesScreen({ appconfig, matches, auth, gtime, dispatch }) {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const isAuth = auth.isLoaded && !auth.isEmpty
  let defaultLanguage = 'FI'
  const history = useHistory()
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedMatch, setSelectedMatch] = useState(null)

  const goToJoinMatch = (match) => {
    dispatch({
      type: 'UPDATE_PAYABLE_MATCH',
      payload: match,
    })
    history.push(appconfig.pathPrefix + '/joinmatch')
  }

  const cancelModal = () => {
    setSelectedMatch(null)
    setConfirmationOpen(false)
  }

  const confirmCancel = (match) => {
    setSelectedMatch(match)
    setConfirmationOpen(true)
  }

  const cancelActivity = async () => {
    setConfirmationOpen(false)
    setLoading(true)
    const matchObject = {
      id: selectedMatch.id,
      customerid: appconfig.accountkey,
    }
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'matches-cancel' : 'matches-cancel'
    let cancel = firebase.functions().httpsCallable(fn)
    try {
      const response = await cancel(matchObject)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        {!isAuth ? (
          <Alert color='warning'>{t('login_to_join_matches')}</Alert>
        ) : null}
        <Typography variant='h6' style={{ paddingBottom: 20 }}>
          {t('matches')}
        </Typography>
        {matches && matches.length > 0 ? (
          <Row>
            {matches.map((m) => (
              <Col key={m.id} xs={12} sm={4}>
                <MatchCard
                  match={m}
                  collection={'matches'}
                  isAuthRequired={true}
                  isAuth={isAuth}
                  lng={defaultLanguage}
                  paymentAction={goToJoinMatch}
                  cancelAction={confirmCancel}
                  uid={auth.uid}
                  // shareClick={shareClick}
                  gtime={gtime}
                />
              </Col>
            ))}
          </Row>
        ) : null}
      </Container>

      <Modal
        size='sm'
        centered
        isOpen={loading}
        backdrop={true}
        wrapClassName={'al-wrap'}
        modalClassName={'al-modal'}
        contentClassName={'loading-spinner'}
      >
        <ModalBody className='text-center'>
          <Spinner color='light' />{' '}
          <p style={{ color: 'white' }}>{t('cancelling')}</p>
        </ModalBody>
      </Modal>

      <Dialog
        open={confirmationOpen}
        onClose={() => cancelModal()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Haluatko varmasti perua osallistumisen?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelModal()} color='secondary'>
            {t('back')}
          </Button>
          <Button onClick={() => cancelActivity()} color='danger' autoFocus>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default withAppconfig(withMatches(MatchesScreen))
