import React, { useState } from 'react'
import { isLoaded, useFirebase } from 'react-redux-firebase'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Button,
  Spinner,
  // Modal,
  // ModalBody,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap'
// import Autocomplete, { usePlacesWidget } from "react-google-autocomplete"
import Collapse from '@material-ui/core/Collapse'
import { Redirect, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
// import Checkbox from '@material-ui/core/Checkbox'
import { useTranslation } from 'react-i18next'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import moment from 'moment'
import 'moment/locale/fi'
import { Divider } from '@material-ui/core'
import PaymentRedirect from '../../screens/PaymentRedirect'
import PaymentOptions from '../../screens/PaymentOptions'
import { useEffect } from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import withAppconfig from '../../AppContext'
import Payments from '../../components/Payments'
import { getWallet } from '../../components/ViewUtils'
import ExtraProduct from '../../components/ExtraProduct'
import useWindowDimensions from '../../useWindowsDimensions'
import OrganisationSelect from '../../components/OrganisationSelect'
import { useUiGet } from '../../utils/useUiGet'
import CheckoutItem from '../../components/CheckoutItem'
import ReservationItem from '../../components/Reservationtem'
import { SPORT_MATCH_CONFIG } from '../../config'
// import AccountSelector from '../../components/AccountSelector'


/*

selected:

category: "tennis"
collection: "reservations"
displayName: "Kenttä 04"
duration: 60
extraprice: 0
id: "kKrlbo8Ve4pLxpvHGI1c"
price: 18
resourceId: "kKrlbo8Ve4pLxpvHGI1c"
selectedduration: 60
start: "2025-02-12T18:30:00Z"
startmoment: "2025-02-12T18:30:00.000Z"
type: "court"
view: "horizontal"

*/

const mapStateToProps = (state, props) => {
  const {
    // match: { selected },
    firebase: { auth, profile },
    firestore,
    globaltime: { gtime },
  } = state
  const match = state.match.selected
  const { appconfig } = props
  let cashsum = 0
  let sportmoneysum = 0
  let cashstoragesum = 0
  let minutestoragesum = 0
  let bl = false
  let blMessage = null
  let allowpostpayment = false
  const rows = []

  const postpaydetails =
    profile.firstname &&
    profile.lastname &&
    profile.email &&
    profile.streetaddress &&
    profile.streetaddress.trim().length > 6 &&
    profile.zip &&
    profile.zip.trim().length === 5 &&
    !isNaN(parseInt(profile.zip)) &&
    profile.city &&
    profile.city.trim().length > 1

  console.log('match', match)
  const selected = { 
    resourceId: match.resource.id,
    start: match.start,
    end: match.end,
    price: 0,
    duration: moment(match.end).diff(moment(match.start), 'minutes'),
    collection: 'reservations',
  }

  
  
  let matchPriceShare = 1

  if (match) {
    const sport = match.sport || ''
    const index = SPORT_MATCH_CONFIG[sport.toLowerCase()].matchtypes.findIndex((x) => x === match.type)
    matchPriceShare = SPORT_MATCH_CONFIG[sport.toLowerCase()].counts[index]
  }
  console.log('SELECTED', selected, 'matchPriceShare', matchPriceShare)
  if (appconfig.cashierpayment) allowpostpayment = true

  if (firestore.data['acls'] && firestore.data['acls'].default) {
    bl = firestore.data['acls'].default.blocked ? firestore.data['acls'].default.blocked : false
    if (bl) blMessage = firestore.data['acls'].default.blockedMessage || null
    allowpostpayment = firestore.data['acls'].default.allowpostpayment || false
  }

  if (firestore.data['wallets'] && selected) {
    let wallet = []
    const walletName = getWallet(appconfig, selected)
    if (firestore.data['wallets'] && firestore.data['wallets'][walletName]) {
      wallet = firestore.data['wallets'][walletName]
    }

    cashsum =
      wallet && wallet.cash
        ? wallet.cash.reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
        : 0

    sportmoneysum =
      wallet && wallet.sportmoney
        ? wallet.sportmoney.reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
        : 0
  }

  if (firestore.data['storages'] && selected) {
    let storage = []
    if (firestore.data['storages'].default) {
      storage = firestore.data['storages'].default
    }

    cashstoragesum =
      storage && storage.cash
        ? storage.cash.reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
        : 0

    minutestoragesum =
      storage && storage.minutes
        ? storage.minutes.reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
        : 0
  }

  // check if selected is array  
  if (selected && Array.isArray(selected)) {
    console.log('selected is array')
    console.log(selected)
    rows.push(...selected)
  }



  const _membrss = Object.keys(firestore.data.memberships || {}).filter((x) => x !== 'undefined' && x !== 'null').map((k) => firestore.data.memberships[k]).filter((v) => v.permanent || gtime.isBetween(moment.utc(v.start), moment(v.end), 'day', '[]'))

  let _organisations = []
  if (firestore.data.organisations && firestore.data.organisations.reservation) {
    _organisations = firestore.data.organisations.reservation.map((v) => {
      return { id: v.organisationId, name: v.name, type: 'organisation' }
    })
  }

  return {
    auth,
    profile,
    wallet: firestore.data['wallets'] || [],
    cashsum,
    sportmoneysum,
    cashstoragesum,
    minutestoragesum,
    memberships: _membrss,
    mems: firestore.data.memberships,
    selected,
    allowpostpayment,
    postpaydetails,
    bl,
    blMessage,
    acls: firestore.data['acls'],
    organisations: _organisations,
    rows,
    matchPriceShare,
    match
  }
}

const enhance = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'memberships', doc: 'active', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'memberships',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'acls', doc: 'active', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'acls',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'organisations', doc: `${props.appconfig.accountkey}` },
      ],
      storeAs: 'organisations',
    },
  ]),
  connect(mapStateToProps)
)

const reservationfns = ['testreservations-paymultiv4', 'reservations-paymultiv4']

const JoinMatchScreen = ({
  auth,
  profile,
  selected,
  cashsum,
  sportmoneysum,
  cashstoragesum,
  minutestoragesum,
  wallet,
  dispatch,
  memberships,
  mems,
  appconfig,
  bl,
  blMessage,
  allowpostpayment,
  postpaydetails,
  acls,
  organisations,
  rows,
  matchPriceShare,
  match
}) => {
  // console.log('ReservationScreen', { auth, profile, selected, cashsum, sportmoneysum, cashstoragesum, minutestoragesum, wallet })
  const firebase = useFirebase()
  let history = useHistory()
  const { t } = useTranslation()
  const uiGet = useUiGet({ appconfig })
  // const [screen, setScreen] = useState('login')
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [renderPayments, setRenderPayments] = useState(false)
  // const [token, setToken] = useState(null)
  const [payments, setPayments] = useState(null)
  const [errorNotification, setErrorNotification] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [cashwalletpayment, setCashwalletPayment] = useState(false)
  // const [sportmoneywalletpayment, setSportmoneywalletPayment] = useState(false)
  // const [cashstorepayment, setCashstorePayment] = useState(false)
  // const [minutestorepayment, setMinutestorePayment] = useState(false)
  // const [onlinepayment, setOnlinePayment] = useState(false)
  // const [cashierpayment, setCashierPayment] = useState(false)
  const [membershipprices, setMembershipPrices] = useState(null)
  const [orgMemberships, setOrgMemberships] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [initdone, setInitDone] = useState(false)
  const [pricescheck, setPricesCheck] = useState(true)
  const [pricescheckrd, setPricesCheckRd] = useState(false)
  // const [streetaddress, setStreetAddress] = useState(profile.streetaddress)
  // const [zip, setZip] = useState(profile.zip)
  // const [city, setCity] = useState(profile.city)
  // const [usernote, setUserNote] = useState(null)
  const [giftcode, setGiftcode] = useState(null)
  const [giftcodeOpen, setGiftcodeOpen] = useState(false)
  const [giftcoderedeem, setGiftcoderedeem] = useState(null)
  const [checkingGiftcode, setCheckingGiftcode] = useState(false)
  // const [savingAddress, setSavingAddress] = useState(false)
  const [selectedmembership, setSelectedmembership] = useState(null)
  const [onlinepaymentprovider, setOnlinePaymentProvider] = useState(null)
  // const [checksum, setChecksum] = useState(null)
  // const [items, setItems] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [extraItems, setExtraItems] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [extraItemsPrice, setExtraItemsPrice] = useState(0)
  const [createdReservation, setCreatedReservation] = useState(null)
  const { width } = useWindowDimensions()
  // [
  //   { id:'a',  value: 'person-a', label: { fi: 'Henkilökohtainen' } },
  //   { id: 'b', value: 'organisation-a', type: 'organisation', label: { fi: 'Yritys A' } },
  //   { id: 'c', value: 'organisation-b', type: 'organisation', label: { fi: 'Yritys B' } },
  // ]
  // )
  // eslint-disable-next-line no-unused-vars
  const [account, setAccount] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState(selected)



  const unallowedmethods = [] //['benefitcard']
  if (!appconfig.storage) {
    if (!appconfig.summerstorage) {
      unallowedmethods.push('cashstore')
    }
  }

  if (!appconfig.cashierpayment) {
    unallowedmethods.push('cashier')
  }
  // console.log('ReservationScreen', unallowedmethods)
  const isBusiness = account ? account.type === 'organisation' : false
  if (isBusiness) {
    unallowedmethods.push('cashwallet')
    unallowedmethods.push('cashstore')
    unallowedmethods.push('sportmoneywallet')
    unallowedmethods.push('minutestore')
  }
  if (!selected) {
    history.push(appconfig.pathPrefix + '/')
  }

  const loaded = isLoaded(profile) && isLoaded(wallet) && isLoaded(mems) && isLoaded(acls)
  const setPaymentProvider = (p) => setOnlinePaymentProvider(p)
  const setPaymentSummary = (sportmoneywallet, cashwallet, online, cashier, cashstore) => {
    const checkedPayments = []
    const selectedPrice = selected.price * 100
    let leftToPay = selectedPrice
    if (sportmoneysum > 0) {
      let charged = 0
      if (sportmoneywallet) {
        if (leftToPay <= sportmoneysum) {
          charged = leftToPay
          leftToPay = 0
        } else {
          charged = sportmoneysum
          leftToPay = leftToPay - sportmoneysum
        }
        checkedPayments.push({ type: 'sportmoneywallet', charged })
      }
    }
    if (cashsum > 0) {
      // console.log('cashsum', cashsum, 'leftToPay', leftToPay)
      let charged = 0
      if (cashwallet && leftToPay > 0) {
        if (leftToPay <= cashsum) {
          charged = leftToPay
          leftToPay = 0
        } else {
          charged = cashsum
          leftToPay = leftToPay - cashsum
        }
        checkedPayments.push({ type: 'cashwallet', charged })
      }
    }
    if (cashstoragesum > 0) {
      // console.log('cashstore', cashstore, 'cashstoragesum', cashstoragesum, 'leftToPay', leftToPay)
      let charged = 0
      if (cashstore && leftToPay > 0) {
        if (leftToPay <= cashstoragesum) {
          charged = leftToPay
          leftToPay = 0
        } else {
          charged = cashstoragesum
          leftToPay = leftToPay - cashstoragesum
        }
        checkedPayments.push({ type: 'cashstore', charged })
      }
    }
    if (leftToPay > 0 && online) {
      checkedPayments.push({ type: 'paytrail', charged: leftToPay })
    } else if (leftToPay > 0 && cashier) {
      checkedPayments.push({ type: 'cashier', charged: leftToPay })
    } else {
      // checkedPayments.push({ type: 'cashier', charged: 0 })
      // checkedPayments.push({ type: 'paytrail', charged: 0 })
    }
    setPayments(checkedPayments)
  }

  const toggleGiftcode = () => {
    if (giftcodeOpen) setGiftcode(null)
    setGiftcodeOpen(!giftcodeOpen)
  }

  const updateItemPricing = (prices) => {
    if (prices && prices.length > 0) {
      prices.sort((a, b) => a.price - b.price)
    }
    let membershipids =  memberships.map((v) => v.membershipid ) //Object.keys(memberships || {}).map((key) => {
    //   return memberships[key].membershipid
    // })
    let membershipprices = prices.filter((x) => {
      if (x.membershipid) {
        return membershipids.includes(x.membershipid)
      } else {
        return true
      }
    })
    console.log('membershipprices', membershipprices)
    if (membershipprices && membershipprices.length > 0) {
      selected.price = membershipprices[0].price
    }
    if (membershipprices.length > 1) {
      selected.overridecost = true
      const gen = membershipprices.find((x) => x.pricing === 'general')
      selected.general = gen
    }
    setMembershipPrices(membershipprices)
    setSelectedSlot(selected)
  }


  const getOrganisationMemberships = (slot) => {
    if (account && account.type === 'organisation') {
      uiGet({ q:'getOrganisationMemberships', organisation: account, customerid: appconfig.accountkey }).then((memberships) => {
        const orgMemberships = []
        if (memberships.data) {
          memberships.data.forEach((m) => {
            orgMemberships.push(m)
          })
        }
        setOrgMemberships(orgMemberships)
      })
    }
  }

  

  const getPrices = (slot) => {
    console.log('getPrices', account)
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testui-get' : 'ui-get'
    const getprice = firebase.functions().httpsCallable(fn)
    setPricesCheck(true)
    getprice({ q: 'getPrice', slot, personid: auth.uid, customerid: appconfig.accountkey, organisation: account ? account.id : 'personal' }).then((prices) => {
      if (prices.data && prices.data.prices && prices.data.prices.length > 0) {
        prices.data.prices.sort((a, b) => a.price - b.price)
      }
      let membershipids = []
        
      if(!isBusiness) {
        membershipids = Object.keys(memberships || {}).map((key) => {
          return memberships[key].membershipid
        })
      } else {
        console.log('orgMemberships', orgMemberships)
        membershipids = orgMemberships ? orgMemberships.map((x) => x.membershipid) : []
      }

      let membershipprices = prices.data.prices.filter((x) => {
        if (x.membershipid) {
          return membershipids.includes(x.membershipid)
        } else {
          return true
        }
      })
      if (membershipprices && membershipprices.length > 0) {
        selected.price = membershipprices[0].price
      }
      if (membershipprices.length > 1) {
        selected.overridecost = true
        const gen = membershipprices.find((x) => x.pricing === 'general')
        selected.general = gen
        setSelectedmembership(membershipprices[0].pricing)
      }
      setMembershipPrices(membershipprices)
      setPricesCheck(false)
      setPricesCheckRd(false)

      if (cashsum > 0 || sportmoneysum > 0) {
        setPaymentSummary(false, true, false, false, false)
        setCashwalletPayment(true)
      } else {
        setPaymentSummary(false, false, false, false, false)
      }
      console.log('selected COUTER getPrice', selected, initdone)
      setInitDone(true)
      setSelectedSlot(selected)
    })
  }

  // const getChecksum = (slot) => {
  //   firebase.functions().region = 'europe-west1'
  //   const getchecksum = firebase.functions().httpsCallable('reservations-createChecksum')
  //   getchecksum({ slot, personid: auth.uid, customerid: appconfig.accountkey }).then((checksum) => {
  //     setChecksum(checksum.data.checksum)
  //   })
  // }

  useEffect(() => {
    const slot = {
      resourceId: selected.resourceId,
      start: selected.start,
      end: selected.end,
    }
    // if (selected.od) {
    //   slot.end = moment(selected.start).utc().add(selected.od, 'minutes').format()
    // }
    console.log('useEffect getPrices orgMemberships')
    getPrices(slot)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgMemberships])


  useEffect(() => {
    if (!membershipprices && selected && !pricescheckrd && isLoaded(memberships)) {
      setPricesCheckRd(true)
      const slot = {
        resourceId: selected.resourceId,
        start: selected.start,
        end: moment(selected.start).utc().add(selected.duration, 'minutes').format(),
      }
      if (selected.od) {
        slot.end = moment(selected.start).utc().add(selected.od, 'minutes').format()
      }
      console.log('useEffect getPrices memberships')
      getPrices(slot)
      // getChecksum(slot)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships])

  // useEffect(() => {
  //   // console.log('useEffect selected addViewCounter', selected)
  //   if (selected && selected.view) {
  //     firebase.functions().region = 'europe-west1'
  //     const fn = appconfig.istest ? 'testui-get' : 'ui-get'
  //     const counter = firebase.functions().httpsCallable(fn)
  //     counter({ q: 'addViewCounter', personid: auth.uid, customerid: appconfig.accountkey, view: selected.view })
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   // console.log('Extras')

  //   const slot = {
  //     resourceId: selected.resourceId,
  //     start: selected.start,
  //     end: moment(selected.start).utc().add(selected.duration, 'minutes').format(),
  //   }

  //   const fn = appconfig.istest ? 'testui-get' : 'ui-get'
  //   const reservationExtras = firebase.functions().httpsCallable(fn)
  //   reservationExtras({ q: 'getExtras', personid: auth.uid, customerid: appconfig.accountkey, slot }).then((items) => {
  //     setItems(items.data)
  //     // console.log('Extras', items.data)
  //   })
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    // const slot = {
    //   resourceId: selected.resourceId,
    //   start: selected.start,
    //   end: moment(selected.start).utc().add(selected.duration, 'minutes').format(),
    // }
    // if (selected.od) {
    //   slot.end = moment(selected.start).utc().add(selected.od, 'minutes').format()
    // }
    if (account && account.type === 'organisation') {
      getOrganisationMemberships()
    } else {
      setOrgMemberships(null)
    }
    // getPrices(slot)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const goHome = () => {
    dispatch({
      type: 'CLEAR_PAYABLE_MATCH',
      payload: null,
    })
    setExtraItems()
    // setItems([])
    setExtraItemsPrice(0)
    history.push(appconfig.pathPrefix + '/')
  }

  const redeemGiftcode = async () => {
    if (giftcode) {
      const isAlready = membershipprices.find((x) => x.code === giftcode)
      if (isAlready) return { error: true, message: 'not_valid_code' }
      setCheckingGiftcode(true)
      setPricesCheck(true)
      firebase.functions().region = 'europe-west1'
      const fn = appconfig.istest ? 'testmemberships-checkgiftcode' : 'memberships-checkgiftcode'
      let checkGiftcode = firebase.functions().httpsCallable(fn)
      try {
        const giftcodeObject = {
          customerid: appconfig.accountkey,
          code: giftcode,
          type: 'courtreservation',
          duration: selected.duration,
          resourceId: selected.resourceId,
          start: selected.start,
          end: selected.end,
        }
        const response = await checkGiftcode(giftcodeObject)
        // alert(JSON.stringify(response.data, null, 2))
        // eslint-disable-next-line no-unused-vars
        const { type, value, checksum, reason } = response.data
        if (type === 'reduction') {
          const _p = (selected.price * value).toFixed(2)
          membershipprices.push({ pricing: 'Etukoodi', price: _p, code: giftcode })
          setGiftcoderedeem(giftcode)
          updateItemPricing(membershipprices)
          setPaymentSummary(false, true, false, false, false)
        } else if (type === 'price') {
          membershipprices.push({ pricing: 'Etukoodi', price: value, code: giftcode })
          setGiftcoderedeem(giftcode)
          updateItemPricing(membershipprices)
          setPaymentSummary(false, true, false, false, false)
        } else if (reason) {
          setGiftcode('')
          alert(reason)
        }
      } catch (error) {
        // console.log(error)
        setGiftcode('')
        alert(error.message)
      }
    }
    setPricesCheck(false)
    setCheckingGiftcode(false)
  }


  const payMatch = async (selectedPayments) => {
    setLoading(true)
    const cartObject = {
      customerid: appconfig.accountkey,
      rids: [{ id: match.reservationid, occurency: { date: moment.utc(match.start).format('YYYY-MM-DD') }}],
      paymentmethod: selectedPayments,
      type: 'matchpayment',
      onetimepayment: true,
    }

    const shouldRenderOptions = selectedPayments.find((x) => x.type === 'online' && x.charged > 0)
    const shouldRedict = selectedPayments.find((x) => x.type === 'online' && x.charged > 0)
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? reservationfns[0] : reservationfns[1]
    const pay = firebase.functions().httpsCallable(fn)
    try {
      let response = await pay(cartObject)
      // console.log(response.data)
      if (response.data.error) {
        setLoading(false)
        setErrorNotification(response.data.error)
      } else if (shouldRenderOptions) {
        setRenderPayments(true)
      } else if (shouldRedict) {
        setRedirect(true)
      } else {
        history.push(appconfig.pathPrefix + '/matches')
      }
    } catch (error) {
      alert(error.message)
      // console.log(error)
      setLoading(false)
    }
  }

  // const createReservation = async (selectedPayments) => {
  //   setLoading(true)
  //   const isReducePriced = selected.overridecost ? true : false
  //   const _resource = { ...selected }
  //   delete _resource.start
  //   delete _resource.duration
  //   delete _resource.overridecost
  //   _resource.locationid = appconfig.accountkey
  //   const reservationObject = {
  //     start: selected.start,
  //     duration: selected.duration,
  //     resource: _resource,
  //     paymentmethod: selectedPayments, // online [{ type }]
  //     reducedprice: isReducePriced,
  //     usernote,
  //     checksum,
  //   }
  //   if (selected.items) {
  //     reservationObject.items = selected.items.map((x) => {
  //       const _x = { ...x }
  //       delete _x.amounts
  //       return _x
  //     })
  //   }
  //   if (account) {
  //     if (account.type === 'organisation') {
  //       reservationObject.organisation = account
  //     }
  //   }
  //   if (isReducePriced && selected.general) reservationObject.genp = selected.general.price
  //   if (selectedmembership) {
  //     reservationObject.membership = selectedmembership
  //   }
  //   if (giftcoderedeem) {
  //     reservationObject.giftcode = giftcode
  //   }

  //   const shouldRedict = selectedPayments.find((x) => x.type === 'online' && x.charged > 0)
  //   const shouldRenderOptions = selectedPayments.find((x) => x.type === 'online' && x.charged > 0)
  //   firebase.functions().region = 'europe-west1'
  //   const fn = appconfig.istest ? 'testreservations-createmultiv3' : 'reservations-createmultiv3'
  //   const createReservationCall = firebase.functions().httpsCallable(fn)
  //   try {
  //     let response = await createReservationCall(reservationObject)
  //     if (response.data.error) {
  //       setLoading(false)
  //       setErrorNotification(response.data.error)
  //     } else if (shouldRenderOptions) {
  //       setCreatedReservation(response.data)
  //       setRenderPayments(true)
  //     } else if (shouldRedict) {
  //       setCreatedReservation(response.data)
  //       setRedirect(true)
  //     } else {
  //       history.push(appconfig.pathPrefix + '/reservations')
  //     }
  //   } catch (error) {
  //     alert(error.message)
  //     // console.log(error)
  //     setLoading(false)
  //   }
  // }

  // const cancelReservation = async (id, locationid) => {
  //   setLoading(true)
  //   firebase.functions().region = 'europe-west1'
  //   const fn = appconfig.istest ? 'testreservations-cancelv2' : 'reservations-cancelv2'
  //   const cancel = firebase.functions().httpsCallable(fn)
  //   try {
  //     const response = await cancel({ id, locationid })
  //     setLoading(false)
  //     if (response.data.error) {
  //       alert(response.data.message)
  //     }
  //     history.push(appconfig.pathPrefix + '/')
  //   } catch (error) {
  //     setLoading(false)
  //     alert(error.message)
  //   }
  // }

  if (!selected) {
    return <Redirect to='/' />
  }

  if (renderPayments) {
    return (
      <PaymentOptions
        selectedGw={'pc'}
        provider={onlinepaymentprovider}
        disabledmethods={appconfig.disabledpaymentmethods || []}
        // showConfirmation={appconfig.bypasspreliminary ? false : true}
        confirmationtype={'reservation'}
        // onCancel={cancelReservation}
        // cancellable={createdReservation}
        forwardToPaytrail={appconfig.forwardToPaytrail}
      />
    )
  } else if (redirect) {
    return <PaymentRedirect selectedGw={'pc'} />
  }

  if (bl) {
    return (
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Row>
            <Col md='8' className='mx-auto'>
              <Card>
                <CardHeader tag='h4'>{t('blocked_header')}</CardHeader>
                <CardBody>
                  <CardTitle tag='h5'>{blMessage || t('blocked_message')}</CardTitle>
                  <Button size='small' color='link' className='text-muted' onClick={() => goHome()}>
                    <ChevronLeftIcon /> {t('back_to_reservation')}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  // const getNoteRow = () => {
  //   return (
  //     <Row className={'loose'}>
  //       <Col>
  //         <FormGroup className='loose-form'>
  //           <Label for='usernote'>{t('usernote')}</Label>
  //           <Input
  //             type='text'
  //             name='usernote'
  //             id='usernote'
  //             value={usernote || ''}
  //             placeholder=''
  //             onChange={(event) => setUserNote(event.target.value)}
  //           />
  //         </FormGroup>
  //       </Col>
  //     </Row>
  //   )
  // }

  // const updateExtraItem = (id, amount) => {
  //   // console.log('updateExtraItem', id, amount)
  //   const item = items.find((x) => x.id === id)
  //   // console.log('found item?', item)

  //   if (item) {
  //     if (!selected.items) selected.items = []
  //     const _selectedItems = [...selected.items]
  //     // console.log('_selectedItems after ...', _selectedItems)
  //     // update seleted item
  //     const _item = _selectedItems.find((x) => x.item.id === item.id)
  //     // console.log('did found _item?', _item)
  //     if (_item) {
  //       if (amount === 0) {
  //         _selectedItems.splice(
  //           1,
  //           _selectedItems.findIndex((x) => x.item.id === item.id)
  //         )
  //       } else {
  //         _item.amount = amount
  //       }
  //     } else {
  //       _selectedItems.push({ item, amount })
  //     }
  //     // console.log('_selectedItems', _selectedItems)
  //     // update extra prices
  //     selected.extraprice = _selectedItems.reduce((a, cur) => a + cur.item.price * cur.amount, 0) / 100
  //     selected.items = _selectedItems
  //     // console.log('selected', selected.extraprice, selected.items)
  //     setExtraItemsPrice(selected.extraprice)
  //     // setExtraItems({ ...extraItems, [item.id]: _p })
  //   }
  // }

  if (!selected || (selected && !selected.items)) {
    selected.extraprice = 0
  }

  // const getExtrasRow = () => {
  //   // console.log('getExtrasRow', extraItems)
  //   if (items && items.length > 0) {
  //     return (
  //       <React.Fragment>
  //         <Row>
  //           <Col xs={12}>
  //             <Label for='items'>{t('available_for_this_reservation')}</Label>
  //           </Col>
  //         </Row>
  //         {items.map((item) => {
  //           return <ExtraProduct key={item.id} product={item} onChange={(id, amount) => updateExtraItem(id, amount)} />
  //         })}
  //       </React.Fragment>
  //     )
  //   }
  //   return null
  // }

  const getGiftcodeRow = () => {
    return (
      <Row className={'loose'}>
        <Col xs={4} sm={3}>
          <div onClick={() => toggleGiftcode()}>
            {giftcodeOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            <Label for='giftcode'>{t('giftcode')}</Label>
          </div>
          <Collapse in={giftcodeOpen}>
            <FormGroup className='loose-form'>
              <Input
                bsSize={'sm'}
                type='text'
                name='giftcode'
                id='giftcode'
                value={giftcode || ''}
                placeholder=''
                onChange={(event) => setGiftcode(event.target.value.toUpperCase())}
              />
            </FormGroup>
            {giftcode ? (
              <Button
                onClick={() => redeemGiftcode()}
                color={'info'}
                disabled={giftcode.length < 3 || checkingGiftcode}
              >
                {checkingGiftcode ? <Spinner size={'sm'} /> : t('redeem_giftcode')}
              </Button>
            ) : null}
          </Collapse>
        </Col>
      </Row>
    )
  }

  const onAccountChange = (e) => {
    setAccount(e)
  }

  console.log('selected COUTER', selected.price, selected)
  return (
    <React.Fragment>
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Row>
            <Col md='8' className='mx-auto'>
              <Card>
                <CardHeader tag='h4'>
                  <Row>
                    <Col>
                      {t('match_joining_confirmation')}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* {rows && rows.length > 0 ? (
                    rows.map((r, i) => 
                      <ReservationItem reservation={r} key={r.id} memberships={memberships} orgMemberships={orgMemberships} account={account} />
                    )
                  ) : null} */}
                  <Row>
                    <Col xs={12} md={6} ><CardTitle tag='h5'>{selected.displayName}</CardTitle></Col>
                    {selected.sponsor ? (
                      <Col xs={12} md={6} className={width > 768 ? 'text-right' : ''}><img src={selected.sponsor} alt='courtsponsor' style={{ maxWidth: 290, marginBottom: 10 }} /></Col>) : null}
                  </Row>
                  {organisations && organisations.length > 0 ? (
                    <OrganisationSelect organisations={organisations} onChange={onAccountChange} />
                  ) : null}
                  <Divider />

                  <Row className={'loose'} key={'day'}>
                    <Col>{t('day')}</Col>
                    <Col style={{ textTransform: 'capitalize' }} className={'text-right'}>
                      {moment(selected.start).utc().format('dddd D.M.YYYY')}
                    </Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'} key={'time'}>
                    <Col>{t('time')}</Col>
                    <Col className={'text-right'}>{moment(selected.start).utc().format('H:mm')}</Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'} key={'duration'}>
                    <Col>{t('duration')}</Col>
                    <Col className={'text-right'}>{selected.duration} min</Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'} key={'sport'}>
                    <Col>{t('sport')}</Col>
                    <Col className={'text-right'}>{match.sport}</Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'} key={'sporttype'}>
                    <Col>{t('sporttype')}</Col>
                    <Col className={'text-right'}>{t(match.type)}</Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'} key={'description'}>
                    <Col>{t('description')}</Col>
                    <Col className={'text-right'}>{t(match.description)}</Col>
                  </Row>
                  {selected.customeralert ? (   
                  <>
                  <Divider />
                  <Row className={'loose'} key={'customeralert'}>
                    <Col className={'text-left'}><Alert style={{ marginBottom: 0 }}>{selected.customeralert}</Alert></Col>
                  </Row>
                  </>
                    ) : null}
                  <Divider />
                  {pricescheck ? (
                    <Spinner size='sm' />
                  ) : membershipprices ? (
                    membershipprices.map((p, i) => {
                      return (
                        <Row key={'membership-prices-' + i} className={'loose'}>
                          <Col>{p.pricing === 'general' ? t('price') : p.pricing}</Col>
                          <Col className={'text-right'}>
                            {i >= 1 ? (
                              <s>{p.price.toFixed(2).replace('.', ',')} €</s>
                            ) : (
                              (p.price/matchPriceShare).toFixed(2).replace('.', ',') + ' € (' + p.price.toFixed(2).replace('.', ',') + ' €)'
                            )}
                            {p.lastminute ? ' ' + t('lastminute_discount') : ''}
                          </Col>

{/* 
                          <Col className={'text-right'}>
                            {i >= 1 ? (
                              <s>{p.price.toFixed(2).replace('.', ',')} €</s>
                            ) : (
                              p.price.toFixed(2).replace('.', ',') + ' €'
                            )}
                            {p.lastminute ? ' ' + t('lastminute_discount') : ''}
                          </Col> */}
                        </Row>
                      )
                    })
                  ) : (
                    <Row className={'loose'} key={'price'}>
                      <Col>{t('price')}</Col>
                      <Col className={'text-right'}>{selected.price.toFixed(2).replace('.', ',')} €</Col>
                    </Row>
                  )}
                  <Divider />
                  {getGiftcodeRow()}
                  <CardText>
                    <small className='text-muted'>
                      {t('cancellation_info').replaceAll('{CANCELLIMIT}', appconfig.cancellimit || '24')}
                    </small>
                  </CardText>
                  <Payments
                    price={
                      (matchPriceShare ? selectedSlot.price / matchPriceShare : selectedSlot.price)  +
                      (selected.extraprice ? selected.extraprice : 0)
                    }
                    selecttype='match'
                    selected={selectedSlot}
                    duration={selectedSlot.duration}
                    sport={selectedSlot.sport}
                    unallowedmethods={unallowedmethods}
                    buttonText={
                      (payments && payments.find((p) => p.type === 'cashier')) || selected.price === 0
                        ? t('reserve')
                        : t('reserve_and_pay')
                    }
                    buttonAction={payMatch}
                    errorNotification={errorNotification}
                    setPaymentProvider={setPaymentProvider}
                    ready={loaded && !pricescheck}
                    businessAccount={
                      account ? account.type === 'organisation' : false
                    }
                  />
                  {pricescheck ? (
                    <Spinner size='sm' />
                  ) : (
                    // <pre>{matchPriceShare} {selected.price} {(matchPriceShare ? selected.price / matchPriceShare : selected.price)}</pre>
                      <pre>{JSON.stringify(selectedSlot, null, 2)}</pre>
                  )}
                  <Button size='small' color='link' className='text-muted' onClick={() => goHome()}>
                    <ChevronLeftIcon /> {t('back_to_reservation')}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {rows && rows.length > 0 ? (
            <pre>{JSON.stringify(rows, null, 2)}</pre>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
  // }
}

export default withAppconfig(enhance(JoinMatchScreen))
